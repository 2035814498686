import * as THREE from 'three'

export class BreitScene extends THREE.Scene {
    async init(){
        throw Error('Abstract!')
    }
    async preRender(){
        throw Error('Abstract!')
    }
    async postRender(){
        throw Error('Abstract!')
    }
    postProcessPass(renderer){
        throw Error('Abstract!')
    }
}
