import {parseGIF, decompressFrames} from 'gifuct-js'

export class Gif {
    constructor() {
        this.isReady = false
        this.oldFrames = []
        this.frames = []
    }

    async init(url) {
        this.oldFrames = [...this.frames]
        this.isReady = false
        let promisedGif = await fetch(url)
        let buffer = await promisedGif.arrayBuffer()
        let gif = await parseGIF(buffer)
        this.frames = decompressFrames(gif, true)
        this.isReady = true
    }

    frameCount() {
        if (!this.isReady) {
            return this.oldFrames.length
        }
        return this.frames.length
    }

    getFrame(index) {
        if (!this.isReady) {
            if (index >= this.oldFrames.length) window.console.error('Bruh des frame gibbet net du Arsch :(')
            return this.oldFrames[index]
        }
        if (index >= this.frames.length) window.console.error('Bruh des frame gibbet net du Arsch :(')
        return this.frames[index]
    }
}
