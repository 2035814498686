import './style/main.css'
import * as THREE from 'three'
import {CubeScene} from './scenes/CubeScene'
import {VRButton} from 'three/examples/jsm/webxr/VRButton.js'
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer.js'
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass.js'
import {ShaderPass} from 'three/examples/jsm/postprocessing/ShaderPass'
import {ParticleScene} from "./scenes/ParticleScene";
import {MeshScene} from "./scenes/MeshScene";

async function app() {
    /**
     let audioCtx = new (window.AudioContext || window.webkitAudioContext)()
     let analyser = audioCtx.createAnalyser()
     analyser.fftSize = 2048
     let bufferLength = analyser.frequencyBinCount
     let dataArray = new Uint8Array(bufferLength)
     let source = audioCtx.createMediaStreamSource(stream);
     source.connect(analyser);
     analyser.connect(distortion);
     distortion.connect(audioCtx.destination);
     /**
     * Sizes
     */
    const sizes = {}
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    const renderer = new THREE.WebGLRenderer({
        canvas: document.querySelector('.webgl'),
        antialias: true,
        autoClear: false
    })
    const composer = new EffectComposer(renderer);
    window.addEventListener('resize', () => {
        // Save sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight

        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()
        vrCamera.aspect = sizes.width / sizes.height
        vrCamera.updateProjectionMatrix()
    })

    window.console.debug(renderer.capabilities)
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(sizes.width, sizes.height)
    if (navigator.xr) {
        try {
            let is_supported = await navigator.xr.isSessionSupported("immersive-vr")
            if (is_supported) {
                document.body.appendChild(VRButton.createButton(renderer))
            }
        } catch {
            console.log('NO VR support :(')
        }
    }
    renderer.xr.enabled = true
    renderer.xr.setReferenceSpaceType('local')

    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
    camera.position.z = 1.75
    const vrCamera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
    vrCamera.aspect = sizes.width / sizes.height
    vrCamera.updateProjectionMatrix()
    const vrGroup = new THREE.Group()
    vrGroup.add(vrCamera)
    const scene = new MeshScene()
    await scene.init(camera)
    scene.add(vrGroup)
    scene.add(camera)
    vrGroup.position.z = 4
    vrGroup.position.y = -1
    const ppPassScene = scene.postProcessPass(renderer)
    composer.addPass(new RenderPass(scene, camera))
    if (ppPassScene != null) {
        composer.addPass(ppPassScene)
    }
    const loop = async () => {
        //analyser.getByteTimeDomainData(dataArray)
        await scene.preRender()
        // Render
        if (renderer.xr.isPresenting) {
            renderer.render(scene, vrCamera)
        } else {
            composer.render()
        }
        await scene.postRender()
    }
    renderer.setAnimationLoop(loop)
}

app().then(() => {})
