import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'
import {BreitScene} from '../breit_scene'
import {Gif} from "../gif";
import * as THREE from "three";
import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass";
import Chroma from "../shaders/chromatic.frag";
import GifMatVert from "../shaders/gif_material.vert";
import GifMatFrag from "../shaders/gif_material.frag";
import {Fog, FogExp2} from "three";

export class MeshScene extends BreitScene {
    async init() {
        this.gif = new Gif()
        await this.gif.init('https://breit.app/api.php')
        this.lastInit = Date.now()
        const frame = this.gif.getFrame(0)
        const texture = new THREE.DataTexture(frame.patch, frame.dims.width, frame.dims.height, THREE.RGBAFormat);
        texture.wrapS = THREE.ClampToEdgeWrapping
        texture.wrapT = THREE.ClampToEdgeWrapping
        this.chrom = new ShaderPass({
            uniforms: {
                iFrame: {value: 0},
                tDiffuse: {value: null}
            },
            fragmentShader: Chroma,
            vertexShader: GifMatVert,
            glslVersion: THREE.GLSL1
        })
        this.material = new THREE.ShaderMaterial({
            uniforms: {
                iFrame: {value: 0},
                gifFrame: {value: texture}
            },
            vertexShader: GifMatVert,
            fragmentShader: GifMatFrag,
            glslVersion: THREE.GLSL1
        })
        const loader = new GLTFLoader();
        let self = this
        loader.load('assets/thinker.glb', function (gltf) {
            gltf.scene.traverse(function (child) {
                if (child.isMesh) {
                    child.material = self.material
                    child.geometry.computeVertexNormals(true)
                }
                gltf.scene.scale.multiplyScalar(1 / 9)
                gltf.scene.position.y -= .1
            })
            self.gltf = gltf
            self.add(gltf.scene)
        })
        this.gifIndex = 0;
        this.lastFrame = Date.now();
        //this.background = new THREE.Color(200,200,200)
    }

    async preRender() {
        if(this.gltf) {
            this.gltf.scene.rotation.y += 0.007
        }
    }

    async postRender() {
        if (Date.now() - this.lastFrame > this.gif.getFrame(this.gifIndex).delay) {
            const nextFrame = new THREE.DataTexture(this.gif.getFrame(this.gifIndex).patch, this.gif.getFrame(this.gifIndex).dims.width, this.gif.getFrame(this.gifIndex++).dims.height, THREE.RGBAFormat);
            nextFrame.wrapS = THREE.ClampToEdgeWrapping
            nextFrame.wrapT = THREE.ClampToEdgeWrapping
            this.material.uniforms.gifFrame.value = nextFrame
            if (this.gifIndex == this.gif.frameCount()) {
                this.gifIndex = 0
            }
            this.lastFrame = Date.now()
            if (Date.now() - this.lastInit > 4000) {
                this.lastInit = Date.now()
                this.gif.init('https://breit.app/api.php').then(() => {
                    this.gifIndex = 0
                })
            }
            this.material.uniforms.iFrame.value = this.gifIndex
        }
    }

    postProcessPass(renderer) {
        return null
    }
}

